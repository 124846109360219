<template>
  <div >
     <div class="content pt-0 pb-0" v-show="($route.path == '/entfaltung-als-konzept' && context == 'inlineConcept')" >
        <div class="columns is-mobile is-multiline">
          <div class="column is-full">
              <span>Nutze den Entfalter light gleich hier - kostenlos und bekomme einen genauen Eindruck von der Arbeitsweise des Tools.</span>
              </div>
              <div class="column is-full-mobile is-full-mobile is-6-desktop">
                <router-link to="/demo">
                  <entfalter-button style="font-size: 0.75em;">Entfalter light</entfalter-button>
                </router-link>
              </div>
              <div class="column is-full-mobile is-full-mobile is-6-desktop">
                <router-link to="/offers">
                <entfalter-button style="font-size: 0.75em;">Produktübersicht</entfalter-button>
              </router-link>



          </div>
        </div>
      </div>

    <section class="section footer blurred__bd pt-1" id="cta" style="min-height: auto;">


      <!-- App Store -->
      <div class="container" v-show="($route.path == '/')" >
        <div class="columns">
          <div class="column">
            <!-- <div class="center">
              <img alt="Entfalter Logo" src="../../../assets.entfalter_shared/img/logo.svg" class="logo"><br/>
            </div> -->
            <h2> Überzeugt?</h2>
            <!-- <h2>Möchtest du dein K&ouml;nnen und Wollen ins Bild gesetzt sehen, <br/>um Entscheidungen zu treffen, die wirklich zu dir passen?</h2>-->

	          <div class="cta--demo">

		          <p>Schaue Dir die komplette Produktpalette des Entfalters an.</p>

		          <router-link to="/offers">
			          <entfalter-button>Zur Produktübersicht</entfalter-button>
		          </router-link>

	          </div>

<!--            <div class="cta&#45;&#45;demo">-->

<!--              <p>Dann nutze den kostenlosen Entfalter light gleich hier. Oder schaue Dir die komplette Produktpalette des Entfalters an.</p>-->


<!--                <entfalter-button @click="$router.push({path: '/demo'})">Zum Entfalter light</entfalter-button>-->
<!--                <router-link to="/offers">-->
<!--                <entfalter-button>Zur Produktübersicht</entfalter-button>-->
<!--              </router-link>-->

<!--            </div>-->

          </div>
        </div>
      <hr/>



      </div>

      <!-- Footer -->
      <footer v-if="!isApp && context != 'inlineConcept'">
        <div class="container">
          <div class="columns is-mobile is-vcentered">
<!--            <div class="column left">-->
<!--              <h4>Social Media</h4>-->
<!--              <a href="https://www.instagram.com/entfalter/" target="_blank" rel="noreferrer">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" width="32" height="32" preserveAspectRatio="xMinYMin" class="jam-icon jam-0">-->
<!--                  <path-->
<!--                      d="M14.017 0h-8.07A5.954 5.954 0 0 0 0 5.948v8.07a5.954 5.954 0 0 0 5.948 5.947h8.07a5.954 5.954 0 0 0 5.947-5.948v-8.07A5.954 5.954 0 0 0 14.017 0zm3.94 14.017a3.94 3.94 0 0 1-3.94 3.94h-8.07a3.94 3.94 0 0 1-3.939-3.94v-8.07a3.94 3.94 0 0 1 3.94-3.939h8.07a3.94 3.94 0 0 1 3.939 3.94v8.07z"></path>-->
<!--                  <path-->
<!--                      d="M9.982 4.819A5.17 5.17 0 0 0 4.82 9.982a5.17 5.17 0 0 0 5.163 5.164 5.17 5.17 0 0 0 5.164-5.164A5.17 5.17 0 0 0 9.982 4.82zm0 8.319a3.155 3.155 0 1 1 0-6.31 3.155 3.155 0 0 1 0 6.31z"></path>-->
<!--                  <circle cx="15.156" cy="4.858" r="1.237"></circle>-->
<!--                </svg>-->
<!--                <span>Auf Instagram</span>-->
<!--              </a>-->
<!--              <br/>-->
<!--              <a href="https://www.facebook.com/entfalter.de" target="_blank" rel="noreferrer">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" width="32" height="32" preserveAspectRatio="xMinYMin" class="jam-icon jam-3">-->
<!--                  <path d="M8.695 6.937v1.377H7.687v1.683h1.008V15h2.072V9.997h1.39s.131-.807.194-1.69h-1.576v-1.15c0-.173.226-.404.45-.404h1.128V5h-1.535C8.644 5 8.695 6.685 8.695 6.937z"></path>-->
<!--                  <path d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0-2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"></path>-->
<!--                </svg>-->
<!--                <span>Auf Facebook</span>-->
<!--              </a>-->
<!--            </div>-->
            <div class="column left">
              <h4>Kontakt und Rechtliches</h4>
              <ul>
                <!-- <li><a href="mailto:kontakt@entfalter.de">Kontakt</a></li> -->
                <!-- <router-link :to="'/about'">Kontakt</router-link> -->
                <li>
                  <router-link :to="'/impressum'">Kontakt &amp; Impressum</router-link>
                </li>
                <li>
                  <router-link :to="'/datenschutz'">Datenschutz</router-link>
                </li>
              </ul>
            </div>
          </div>

          <!-- Legal -->
          <div class="columns">
            <div class="column">
              <small>&copy; OmniMundi GmbH &amp; Co. KG &middot; </small>
            </div>
          </div>
        </div>
      </footer>
    </section>

    <!-- Simple mobile nav bottom -->
    <nav v-if="isApp">
      <ul>
        <li>
          <router-link to="/">
            <span class="jam jam-home"></span>
            Home
          </router-link>
        </li>
        <li>
          <router-link to="/demo">
            <span class="jam jam-write"></span>Test
          </router-link>
        </li>
        <li>
          <router-link to="/offers">
            <span class="jam jam-coin"></span>Preise
          </router-link>
        </li>
        <li>
        <router-link to="/info">
          <span class="jam jam-lightbulb"></span>Info
        </router-link>
        </li>

      </ul>
    </nav>

  </div>
</template>
<script>
import EntfalterButton from "@/components/entfalter-button.component";

export default {
  name: 'Footer',
  components: {EntfalterButton},
  props: ["context"],
  computed: {
    demoAvailable() {
      return !(this.$utils.validateLocalStorageData('storedData') || this.$route.path === '/demo' || this.$route.path === '/match')
    },
    isApp() {
      return (process.env.CORDOVA_PLATFORM)
    }
  },
  methods: {
    goAndBuy() {
      // TODO: Route to app.
    }
  }
}
</script>
<style lang="scss" scoped>

section.footer {
  padding-bottom : 4rem;
}

// A dedicated app navigation
nav {
  position   : fixed;
  z-index    : 1000;
  bottom     : 0;
  width      : 100%;
  height     : 48px;
  background : white;
  color      : black;
  //box-shadow: rgba(50, 50, 93, 0.25) 0px -2px 5px 1px, rgba(0, 0, 0, 0.3) 0px -1px 3px 1px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px -1px 0px, rgba(17, 17, 26, 0.1) 0px -8px 24px, rgba(17, 17, 26, 0.1) 0px -16px 48px;

  ul {
    margin          : 0;
    padding         : 5px;
    display         : flex;
    justify-content : space-evenly;

    li {
      margin         : 0 10px;
      font-size      : 9px;
      text-transform : uppercase;
      letter-spacing : 0.06em;
      line-height    : 1;

      a {
        color : black;

        &.router-link-exact-active {
          color : entfalter-color(link, base);
        }
      }

      // Icons
      .jam {
        display       : block;
        font-size     : 25px;
        margin-bottom : 3px;
      }

    }
  }
}
</style>
