<template>
	<div>
		<div class="nav__backdrop blurred__bd" @click="handleClick"></div>

		<router-link to="/"><img ref="" alt="Entfalter Logo" src="../../../assets.entfalter_shared/img/logo.svg" class="elogo"/></router-link>

		<nav id="nav_top" class="is-vcentered blurred__bd is-justify-content-flex-end is-flex">
			<div class="columns">
				<div class="column is-hidden-touch ">
					<router-link to="/entfaltung-als-konzept">Entfaltung als Konzept</router-link>
				</div>
				<div class="column is-hidden-touch">
					<router-link to="/spiel">Karten &amp; Spiele</router-link>
				</div>
				<div class="column is-hidden-touch">
					<router-link to="/offers">Preise &amp; Optionen</router-link>
				</div>
<!--				<div class="column is-hidden-touch ml-2">-->
<!--					<router-link to="/demo">Jetzt testen</router-link>-->
<!--					&lt;!&ndash;					<entfalter-button class="m-0" @click="$router.push({path:'/demo'})">Jetzt Testen</entfalter-button>&ndash;&gt;-->
<!--				</div>-->
				<div class="column is-hidden-touch">
					<a href="https://app.entfalter.de" class="special">Login</a>
					<!--					<entfalter-button class="m-0" @click="openLink('https://app.entfalter.de')">Login</entfalter-button>-->
				</div>
			</div>
		</nav>

		<nav id="nav_aside" @click="handleClick">
			<div class="columns is-mobile is-multiline">
				<div class="column">
					<router-link to="/">Entfaltung per App</router-link>
					<router-link to="/entfaltung-als-konzept">Entfaltung als Konzept</router-link>
					<router-link to="/spiel">Karten &amp; Spiel</router-link>
					<router-link to="/offers">Preise &amp; Optionen</router-link>
					<router-link to="/about">Über uns</router-link>

<!--					<entfalter-button class="right" @click="$router.push({path:'/demo'})">Jetzt testen</entfalter-button>-->
					<entfalter-button @click="openLink('https://app.entfalter.de')">Login</entfalter-button>

				</div>
			</div>
			<!-- Small -->
			<div class="meta">
				<router-link v-bind:to="'/impressum'">Impressum</router-link>
				<router-link v-bind:to="'/datenschutz'">Datenschutz</router-link>
				<br/><br/>
			</div>
		</nav>
		<button :class="[ open ? 'is-active' : '', 'menu__handle']" @click="handleClick" aria-label="Navigation" title="Navigation">
			<span class="burger-icon"></span>
		</button>
	</div>
</template>
<script>
import EntfalterButton from "./entfalter-button.component";

export default {
	name:       'NavigationTop',
	components: {EntfalterButton},
	data() {
		return {
			open: false
		}
	},
	methods: {
		handleClick() {
			if (!this.open) {
				document.body.classList.add('nav__open')
				this.open = true
			} else {
				document.body.classList.remove('nav__open')
				this.open = false
			}
		},

		openLink(to) {
			window.location.href = to
		}
	}
}
</script>
