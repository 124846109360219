<template>
    <transition name="fade">
        <div class="e__notification blurred__bd blue" v-if="!opted">
            <div>
                Wir nutzen Google Analytics. Du kannst dies unseren
                <router-link style="color: white;font-weight: bold;" :to="'/datenschutz'">Datenschutzbedigungen</router-link>
                jederzeit ein- oder abschalten.
                <button v-on:click.prevent="gaDone(0)">Okay</button>
                <button v-on:click.prevent="gaDone(1)">Abschalten</button>
            </div>
        </div>
    </transition>
</template>
<script>
	export default {
		name: 'NotificationComponent',
		data() {
			return {
				opted: localStorage.getItem('www_entfalter_tracking_optout')
			}
		},
		created() {
			// Nothing.
		},
		methods: {
			/**
             * Opting in or out
			 * @param val INT 1 = out, 0 = in
			 */
			gaDone(val) {
				localStorage.setItem('www_entfalter_tracking_optout', val)
				this.opted = true
			}
		}
	}
</script>
