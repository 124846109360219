<template>
    <div :class="{'audioplayer':true, 'loading':loading}">
        <div class="audio_control" :id="id" @click.prevent="togglePlay()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.2 134.2">
                <defs>
                    <linearGradient :id="'floodgradient_'+id" x1="0" x2="0" y1="0" y2="1">
                        <stop :id="'oblack_'+id" ref="oblack" offset="100%" stop-color="black"></stop>
                        <stop offset="0%" stop-color="white"></stop>
                    </linearGradient>
                    <mask :id="'floodmask_'+id">
                        <path
                                :fill="'url('+base_href+'#floodgradient_' + id + ')'"
                                d="M102.9,8.3H74.4L54.2,28.4v29l20.2,19.8h28.5L123.1,57V28.5L102.9,8.3z"
                        ></path>
                    </mask>

                    <radialGradient
                            :id="'shadow_1_'+id"
                            cx="107.9475"
                            cy="62.3565"
                            r="62.095"
                            fx="81.3216"
                            fy="34.8315"
                            gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.4913" style="stop-color:#000000;stop-opacity:0.5"></stop>
                        <stop offset="0.9996" style="stop-color:#000000;stop-opacity:0"></stop>
                    </radialGradient>

                    <radialGradient
                            :id="'SVGID_1_'+id"
                            cx="-4159.3027"
                            cy="769.7211"
                            r="23.5957"
                            gradientTransform="matrix(0 -1 1 0 -652.5751 -4129.0464)"
                            gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" style="stop-color:#E6E7E8"></stop>
                        <stop offset="1" style="stop-color:#E6E7E8"></stop>
                    </radialGradient>

                    <radialGradient
                            :id="'SVGID_2_'+id"
                            cx="76.1653"
                            cy="14.2"
                            r="23.5655"
                            gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" style="stop-color:#F2F2F2"></stop>
                        <stop offset="0.4763" style="stop-color:#F1F1F2"></stop>
                        <stop offset="1" style="stop-color:#FAFAFA"></stop>
                    </radialGradient>

                    <radialGradient
                            :id="'SVGID_3_'+id"
                            cx="-4184.2466"
                            cy="712.7404"
                            r="23.6125"
                            gradientTransform="matrix(0 -1 1 0 -652.5751 -4129.0464)"
                            gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" style="stop-color:#E6E7E8"></stop>
                        <stop offset="1" style="stop-color:#E6E7E8"></stop>
                    </radialGradient>

                    <radialGradient
                            :id="'SVGID_4_'+id"
                            cx="-4200.2773"
                            cy="753.6904"
                            r="21.5076"
                            fx="-4207.4087"
                            fy="758.3304"
                            gradientTransform="matrix(0 -1 1 0 -652.5751 -4129.0464)"
                            gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" style="stop-color:#FFFFFF"></stop>
                        <stop offset="0.772" style="stop-color:#FFFFFF"></stop>
                    </radialGradient>

                    <radialGradient
                            :id="'SVGID_5_'+id"
                            cx="88.6653"
                            cy="42.75"
                            r="34.45"
                            fx="104.0492"
                            fy="66.2851"
                            gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="2.995835e-08" style="stop-color:#000000;stop-opacity:0.12"></stop>
                        <stop offset="0.8243" style="stop-color:#000000;stop-opacity:0.35"></stop>
                    </radialGradient>

                </defs>


                <path
                        id="shadow"
                        :fill="'url('+base_href+'#shadow_1_'+id+')'"
                        style="opacity:0.5;"
                        d="M45.9,60.4l25,25c20.9,16.5,24.8,20.2,45.1,36.7c16.7,13.6,68.9-34.7,49.8-56.4c-16.6-19-18.7-22-34.5-40.5l-25-25l-3.4,8.3l20.2,20.2v28.5l-20.2,20.2H74.4L54.2,57.6L46,25.2l-0.1,3.4V60.4z"
                ></path>

                <g>
                    <g>
                        <path
                                :id="'flood_'+id"
                                ref="flood"
                                class="flood"
                                :mask="'url('+base_href+'#floodmask_' + id + ')'"
                                d="M102.9,8.3H74.4L54.2,28.4v29l20.2,19.8h28.5L123.1,57V28.5L102.9,8.3z"
                        ></path>
                    </g>
                    <g>

                        <path
                                :fill="'url('+base_href+'#SVGID_1_'+id+')'"
                                d="M123.2,57V28.5L103,8.3l3.4-8.3l25,25v35.3L123.2,57z"
                        ></path>


                        <path
                                :fill="'url('+base_href+'#SVGID_2_'+id+')'"
                                d="M103,8.3H74.5L54.3,28.4L46.1,25l25-25h35.3L103,8.3z"
                        ></path>


                        <path
                                :fill="'url('+base_href+'#SVGID_3_'+id+')'"
                                d="M54.3,28.5V57l20.2,20.2L71,85.4l-25-25V25L54.3,28.5z"
                        ></path>


                        <path
                                :fill="'url('+base_href+'#SVGID_4_'+id+')'"
                                d="M74.5,77.2H103L123.1,57l8.3,3.4l-25,25H71L74.5,77.2z"
                        ></path>
                    </g>


                    <path
                            :fill="'url('+base_href+'#SVGID_5_'+id+')'"
                            opacity="0.45"
                            d="M102.9,8.3H74.4L54.2,28.4v29l20.2,19.8h28.5L123.1,57V28.5L102.9,8.3z"
                    ></path>
                </g>

                <g :id="'i_play_'+id" ref="i_play" v-show="!loading">
                    <line class="i_play" x1="101.6" y1="43.2" x2="77.8" y2="54"></line>
                    <line class="i_play" x1="77.8" y1="32.5" x2="101.6" y2="43.2"></line>
                    <line class="i_play" x1="77.8" y1="32.5" x2="77.8" y2="54"></line>
                </g>
                <g :id="'i_play_m_'+id" ref="i_play_m" :mask="'url('+base_href+'#floodmask_' + id + ')'">
                    <line class="i_play white" x1="101.6" y1="43.2" x2="77.8" y2="54"></line>
                    <line class="i_play white" x1="77.8" y1="32.5" x2="101.6" y2="43.2"></line>
                    <line class="i_play white" x1="77.8" y1="32.5" x2="77.8" y2="54"></line>
                </g>

                <g :id="'i_pause_'+id" ref="i_pause" class="inactive">
                    <line class="i_pause" x1="82.9" y1="33.7" x2="82.9" y2="51.8"></line>
                    <line class="i_pause" x1="92.3" y1="33.7" x2="92.3" y2="51.8"></line>
                </g>
                <g
                        :id="'i_pause_m_'+id"
                        ref="i_pause_m"
                        :mask="'url('+base_href+'#floodmask_' + id + ')'"
                        class="inactive"
                >
                    <line class="i_pause white" x1="82.9" y1="33.7" x2="82.9" y2="51.8"></line>
                    <line class="i_pause white" x1="92.3" y1="33.7" x2="92.3" y2="51.8"></line>
                </g>
                <g v-if="audioState.muted">
                    <circle cx="115" cy="75" r="12" stroke="#990000" stroke-width="3" fill="none"/>
                    <line x1="109.5" y1="65.2" x2="120.5" y2="85.7" stroke="#990000" stroke-width="4"/>
                </g>
                <g v-if="loading">
                    <path id="loading" d="M93,42.6l7.7-13.5c0.4-0.6,0.4-1.4,0-2.1s-1.1-1-1.8-1H78.2c-0.7,0-1.4,0.4-1.8,1s-0.4,1.4,0,2.1l7.7,13.5
	l-7.7,13.5c-0.4,0.6-0.4,1.4,0,2.1c0.4,0.6,1.1,1,1.8,1h20.7c0.7,0,1.4-0.4,1.8-1s0.4-1.4,0-2.1L93,42.6z M78.2,57.2l8.3-14.5
	l-8.3-14.5h20.7l-8.3,14.5l8.3,14.5H78.2z M88.5,43.2l6.8,11.9H81.8L88.5,43.2z M88.5,42.1l-3.2-5.7h6.5L88.5,42.1z"/>
                </g>
            </svg>
        </div>
    </div>
</template>


<script>
	// VISUALISER: https://codepen.io/nfj525/pen/rVBaab
	import { gsap } from 'gsap'

	export default {
		name: 'AudioPlayer',
		components: {},

		props: {
			sound: {
				type: String,
				default: null
			},
			autoplay: {
				type: Boolean,
				default: true
			},
			type: {
				// Actually the currentBranchId
				type: [String, Number],
				default: 0
			},
			cuePoints: {
				type: Array,
				default: () => {
					return []
				}
			}
		},

		data() {
			return {
				audioState: {},
				showModal: false,
				soundNotificationShown: false, // Once flagged do not show again
				base_href: '',
				id: null,
				audio: null,
				state: 'paused',
				position: 0,
				delay: 2000, // Give users some time to  breathe
				resuming: false,
				loading: false  // initially false, as we show a load indicator ony after user action
			}
		},

		mounted() {
			this.base_href = ''
			this.id = 'audio_' + this._uid

			// Observe audio state
			this.audio = new Audio()
			this.audio.preload = 'none'
			this.audio.addEventListener('timeupdate', this.onProgress, false)
			this.audio.addEventListener('ended', this.onEnd, false)
			this.audio.addEventListener('waiting', this.onWaiting, false)
			this.audio.addEventListener('canplaythrough', this.onCanPlay, false)
			// Initial fill
			gsap.set(this.$refs['flood'], {
				fill: '#3cb1e6'    //this.$utils.getColors([this.type])
			})
			// Start on init, if there is a sound
			this.setSound()
		},

		watch: {
			sound() {
				this.setSound()
			}
		},

		methods: {
			setSound() {
				if (this.sound) {
					this.audio.src = this.sound
					// Re-Update Mute state
					this.audio.muted = this.audioState.muted
					// Pre-Buffer
					// setTimeout(this.audio.pause.bind(this.audio), 10);
					// Emit pre-play
					this.$emit('audio-sound-set')
					// Autoplay, if requested
					if (this.autoplay) {
						window.setTimeout(() => {
							this.play()
						}, this.delay)
					}
				} else {
					// ...
				}
			},
			play() {
				//DEV
				// this.$emit('audio-no-autoplay')
				// Stop all others : This won't work as we're not using the audio tag
				// var sounds = document.getElementsByTagName('audio');
				// for (let i = 0; i < sounds.length; i++) sounds[i].pause();

				if (this.audio) {
					let playPromise = this.audio.play();
					if (typeof playPromise !== "undefined") {
						playPromise.then(() => {
							// Automatic playback started!
							this.$emit('audio-started', this, this.resuming)
							this.state = 'playing'
							gsap.to(this.$refs['i_play'], 0.2, {display: 'none', alpha: 0})
							gsap.to(this.$refs['i_play_m'], 0.2, {display: 'none', alpha: 0})
							gsap.to(this.$refs['i_pause'], 0.2, {display: 'block', alpha: 1})
							gsap.to(this.$refs['i_pause_m'], 0.2, {display: 'block', alpha: 1})

						}).catch(() => {
							// Automatic playback failed.
							this.$emit('audio-no-autoplay')
						})
					}

				}
			},
			pause(andReset) {
				this.$emit('audio-paused', this)
				this.audio.pause()
				this.state = 'paused'
				this._setUIPaused()
				if (andReset) {
					this.resuming = false
					this.audio.currentTime = 0
				} else
					this.resuming = true
			},
			togglePlay() {
				this.$emit('click')
				return this.audio.paused ? this.play() : this.pause()
			},
			// Video is buffering
			onWaiting() {
				this.loading = true
			},
			// Video has buffered and can play
			onCanPlay() {
				this.loading = false
			},
			/**
			 * Animate progress
			 **/
			onProgress() {
				if (!this.audio) return
				let value = 0
				if (this.audio.currentTime > 0) {
					value = Math.floor(
						(100 / this.audio.duration) * this.audio.currentTime
					)
				}
				if (this.$refs['oblack']) {
					gsap.to(this.$refs['oblack'], 0.2, {
						attr: {offset: 100 - value + '%'}
					})
				} else {
					// TODO: this is a hack. Properly end audio on leave!
					this.audio.pause()
				}
				// Emit cue point events [ break down to half second ]
				// TODO: toFixed = wrong arg
				let fTime = Math.floor(this.audio.currentTime.toFixed(2) * 2) / 2
				if (this.cuePoints.includes(fTime)) {
					// console.info(':::::: CUE POINT @', fTime, this.cuePoints.indexOf(fTime))
					this.$emit('audio-cuepoint', this.cuePoints.indexOf(fTime))
				}
			},

			onEnd() {
				this.$emit('audio-ended')
				this.state = 'ended'
				this._setUIPaused()
			},

			_setUIPaused() {
				gsap.to(this.$refs['i_play'], 0.2, {display: 'block', alpha: 1})
				gsap.to(this.$refs['i_play_m'], 0.2, {display: 'block', alpha: 1})
				gsap.to(this.$refs['i_pause'], 0.2, {display: 'none', alpha: 0})
				gsap.to(this.$refs['i_pause_m'], 0.2, {display: 'none', alpha: 0})
			},

			closeModal() {
				this.showModal = false
			}
		},
		/**
		 * CleanUp
		 */
		beforeDestroy() {
			this.audio.pause()
			this.audio.removeEventListener('timeupdate', () => {})
			this.audio.removeEventListener('ended', () => {})
			this.audio = null
			delete this.audio

		}
	}
</script>

<style lang="scss" scoped>

    .loading {
        opacity : 0.5;
    }

    .flood {
        opacity : 0.45;
    }

    .i_play {
        fill              : none;
        stroke            : #666666;
        stroke-width      : 2;
        stroke-linecap    : round;
        stroke-linejoin   : round;
        stroke-miterlimit : 10;
    }

    .i_pause {
        fill              : none;
        stroke            : #666666;
        stroke-width      : 2;
        stroke-linecap    : round;
        stroke-miterlimit : 10;
    }

    .white {
        stroke : #ffffff;
    }

    .inactive {
        display : none;
    }

    .st0 {
        opacity : 0.5;
    }

    .st5 {
        opacity : 0.46;
        fill    : #bf0023;
    }

    .st6 {
        opacity : 0.46;
    }
</style>
