<template>
	<button :class="[disabled ? 'btn svg disabled' : '', 'btn svg']" :tabindex="tabindex" @mouseenter="mouseOver" @mouseleave="mouseOut" @click="clickWatcher($event)">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 254 75.2">

			<defs>
				<linearGradient :id="'floodgradient_'+id" x1="0" x2="0" y1="0" y2="1">
					<stop ref="oblack" class="fill" offset="100%" stop-color="black"/>
					<stop offset="0%" stop-color="white"/>
				</linearGradient>

				<linearGradient :id="'SVGID_1_'+id" gradientUnits="userSpaceOnUse" x1="123.7235" y1="7.6024" x2="128.8688" y2="69.2972">
					<stop offset="0.1508" stop-opacity="0.6" stop-color="#808080"/>
					<stop offset="0.3466" stop-opacity="0.4616" stop-color="#838383"/>
					<stop offset="0.4939" stop-opacity="0.3576" stop-color="#8D8D8D"/>
					<stop offset="0.6253" stop-opacity="0.2648" stop-color="#9D9D9D"/>
					<stop offset="0.7474" stop-opacity="0.1785" stop-color="#B4B4B4"/>
					<stop offset="0.8631" stop-opacity="0.1" stop-color="#D1D1D1"/>
					<stop offset="0.9725" stop-opacity="0" stop-color="#F5F5F5"/>
					<stop offset="1" stop-opacity="0" stop-color="#FFFFFF"/>
				</linearGradient>

				<!--                Top shadow / inner-->
				<linearGradient :id="'SVGID_2_'+id" gradientUnits="userSpaceOnUse" x1="106.7299" y1="-12.4853" x2="110.1572" y2="28.6101">
					<stop offset="0.1508" stop-opacity="0.4"/>
					<stop offset="0.3466" stop-opacity="0.2616"/>
					<stop offset="0.4939" stop-opacity="0.1576"/>
					<stop offset="0.6253" stop-opacity="0.1048"/>
					<stop offset="0.7474" stop-opacity="0.0785"/>
					<stop offset="0.8631" stop-opacity="0"/>
					<stop offset="0.9725" stop-opacity="0"/>
					<stop offset="1" stop-opacity="0"/>
				</linearGradient>

				<radialGradient :id="'SVGID_4_'+id" cx="2359.9956" cy="2773.9768" r="12.9981" gradientTransform="matrix(-1 0 0 -1 2571.0085 2790.644)" gradientUnits="userSpaceOnUse">
					<stop offset="0" stop-color="#FFFFFF"/>
					<stop offset="0.4763" stop-color="#F1F1F2"/>
					<stop offset="1" stop-color="#F1F1F2"/>
				</radialGradient>

				<radialGradient :id="'SVGID_3_'+id" cx="2563.1738" cy="2760.2271" r="12.9912" gradientTransform="matrix(-1 0 0 -1 2571.0085 2790.644)" gradientUnits="userSpaceOnUse">
					<stop offset="0" stop-color="#FFFFFF"/>
					<stop offset="0.4763" stop-color="#F1F1F2"/>
					<stop offset="1" stop-color="#F1F1F2"/>
				</radialGradient>
			</defs>


			<mask :id="'floodmask_'+id">
				<path :fill="'url('+base_href+'#floodgradient_' + id + ')'"
				      d="M214.5,31.4l-11.2,11.1h-15.7l-171.8,0L4.7,31.4V15.7L15.9,4.6h15.7l171.8,0l11.1,11.1V31.4z"/>
			</mask>

			<path class="flood" ref="fill" :mask="'url('+base_href+'#floodmask_' + id + ')'"
			      d="M214.5,31.4l-11.2,11.1h-15.7l-171.8,0L4.7,31.4V15.7L15.9,4.6h15.7l171.8,0l11.1,11.1V31.4z"/>

			<g>
				<path :fill="'url('+base_href+'#SVGID_1_' + id + ')'"
				      d="M26.3,69.7c1.3,1.8,3.3,2.9,5.6,3.1c27.9,1.6,208.1,5.5,217.4-2.4c6.4-5.4,5.5-14.1,1.6-17.3l-32.1-39.2v19.9L205,47.1L13.8,47L0,33.2L26.3,69.7z"/>
				<path :fill="'url('+base_href+'#SVGID_2_' + id + ')'" d="M4.5,15.7c0,5.6,0,1.6,0,12.7c23.7,1.9,170.8,1.9,209.8,0c-0.2-15.7,0.9-11.9,0-12.7L203.2,4.6l-187.5,0L4.5,15.7z"/>
				<path class="bdr_3" d="M31.4,4.5H15.7L4.5,15.7L0,13.8L13.8,0h19.5L31.4,4.5z"/>
				<path class="bdr_lb" :fill="'url('+base_href+'#SVGID_3_' + id + ')'" d="M4.5,15.7v15.7l11.1,11.1L13.8,47L0,33.2V13.8L4.5,15.7z"/>
				<path class="bdr_1" d="M187.5,42.5h15.7l11.2-11.1l4.5,1.9L205,47.1h-19.5L187.5,42.5z"/>
				<path class="bdr_tr" :fill="'url('+base_href+'#SVGID_4_' + id + ')'" d="M214.3,31.4V15.7L203.2,4.6L205,0l13.8,13.8v19.5L214.3,31.4z"/>
				<polygon class="bdr_3" points="205,0 33.3,0 31.4,4.5 203.2,4.6 	"/>
				<polygon class="bdr_1" points="187.5,42.5 15.7,42.5 13.8,47 185.6,47.1 	"/>
			</g>


		</svg>
		<span :ref="'label'" :class="['label ', colorscheme, (disabled) ? 'disabled' : '']">
            <span v-show="!busy"><slot></slot></span>
            <img v-show="busy" alt="Moment"
                 src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
        </span>
	</button>
</template>
<script>
import { TweenMax, TimelineMax, Back } from 'gsap'

export default {
	name:  'entfalter-button',
	props: {
		disabled:    {
			type:    Boolean,
			default: false
		},
		busy:        {
			type:    Boolean,
			default: false
		},
		colorscheme: {
			type:    String,
			default: 'dark' // or light. Latter makes the label white
		},
		tabindex:    {
			type:    Number,
			default: -1
		}
	},
	data() {
		return {
			base_href: '',
			id:        null,
			fillTo:    0,
			// TODO: use smil
			fillTween: new TimelineMax()
		}
	},


	mounted() {
		this.id = 'btn_' + this._uid
		this._updateFill()
	},
	watch:   {
		'$props': {
			handler:   function () {
				this._updateFill()
			},
			deep:      true,
			Immediate: true
		}
	},
	methods: {
		clickWatcher(e) {
			if (!this.disabled && !this.busy) {
				this.$emit('click', e)
			} else {
				e.preventDefault()      //
			}
		},
		mouseOver() {
			this.fillTo = 100
			this._hoverFill()
		},

		mouseOut() {
			this.fillTo = 0
			this._hoverFill()
		},

		_updateFill() {
			if (!this.$refs.oblack) return;
			// Fill Button by Type [ remove this. superflous ]
			if (this.disabled) {
				// Set disabled state
				TweenMax.set(this.$refs.fill, {fill: this.$utils.getColors(5)})
				TweenMax.to(this.$refs.oblack, 0.36, {attr: {offset: 100 + '%'}, ease: Back.easeOut})
				// TweenMax.set(this.$refs.label, {className: 'disabled'})
				return
			}
			TweenMax.set(this.$refs.fill, {fill: this.$utils.getColors(this.type)})
		},

		_hoverFill() {
			if (this.busy || !this.$refs.oblack) return;
			// Move filling
			this.fillTween.clear()
			this.fillTween
			    .to(this.$refs.oblack, 0.36, {attr: {offset: (100 - this.fillTo) + '%'}, ease: Back.easeOut})
			// // TODO: this causes the :class attr not to work - it overwrites it!
			    .to(this.$refs.label, 0.16, {className: (this.fillTo > 99 && this.colorscheme === 'label dark') ? 'label light' : 'label dark'}, "-=0.36")

		}
	},
	beforeDestroy() {
		// this.fillTween.kill()
	}
}

</script>


<style lang="scss" scoped>


button {

	cursor      : pointer;
	margin-left : 6px;

	&:hover {
		.fill {
			// TODO. This needs another approach for SVG e.g. height
			}
		}

	&:focus {
		svg {
			.bdr_1 {
				fill : rgba(0, 100, 200, 0.3);
				}

			.bdr_3 {
				fill : rgba(0, 100, 200, 0.2);
				}

			.bdr_lb {
				fill : rgba(0, 100, 200, 0.1);
				}

			.bdr_tr {
				fill : rgba(0, 100, 200, 0.1);
				}
			}
		}
	}

/* Special */
.btn.disabled {
	color  : #999;
	cursor : not-allowed;

	.label span {
		color : #999 !important;
		}
	}

.bdr_1 {
	fill : #FFFFFF;
	}

.bdr_3 {
	fill : #F9F9F9;
	}

.flood {
	fill    : #0099cc;
	opacity : 0.45;
	}

svg {
	position : absolute;
	top      : 0;
	left     : 0;
	width    : 100%;
	}

.label {
	display     : block;
	left        : 0;
	right       : 0;
	width       : 88%;
	font-size   : 0.85em;
	font-weight : 400;
	margin-top  : 1.5px !important;

	&.light span {
		color : white;
		}

	&.dark span {
		color : black;
		}

	}


.label i.big {
	position : absolute;
	left     : 10%;
	top      : 6px;
	}


</style>
