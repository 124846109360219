import Vue       from "vue";
import VueRouter from "vue-router";
import Home      from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path:      "/",
		name:      "Home",
		component: Home,
		meta:      {
			title: "Entfalter - das Tool für kluge Berufs- und Lebensentscheidungen",
		},
	},
	{
		name:      "Willkommen",
		path:      '/willkommen/:id?',
		component: Home,

	},
	{
		name:      "About",
		path:      "/about",
		alias:     "/ueber-uns",
		component: () =>
			           import(/* webpackChunkName: "aboutus" */ "../views/About.vue"),
	},
	{
		path:      "/spiel",
		alias:     "/dasspiel",
		component: () => import(/* webpackChunkName: "spiel" */ "../views/Spiel.vue"),
		meta:      {
			title: "Der Entfalter - Ein Kompass im Spiel des Lebens",
		}
	},
	{
		path:      "/kompass",
		name:      'kompass',
		component: () => import(/* webpackChunkName: "spiel" */ "../views/cardgame/SpielDigital.vue"),
		meta:      {
			title: "Der Entfalter - Ein Kompass im Spiel des Lebens",
		},
		// redirect: '/kompass/start',
		children: [
			{
				path:      "ausgewaehlt",
				alias:     "berufsentwicklungsspiel",
				name:      'berufsspiel',
				component: () => import(/* webpackChunkName: "spiel" */ "../views/cardgame/KompassBeruf.vue"),
				meta:      {
					title: "Ausgewählt - dass Berufsentwicklungsspiel",
				},
			},
			{
				path:      "aufgeraeumt",
				alias:     "rollenspiel",
				name:      'rollenspiel',
				component: () => import(/* webpackChunkName: "spiel" */ "../views/cardgame/KompassRollen.vue"),
				meta:      {
					title: "Aufgeräumt - das Rollenspiel",
				},
			},
			{
				path:      "hausgemacht",
				alias:     "biografiespiel",
				name:      'biografiespiel',
				component: () => import(/* webpackChunkName: "spiel" */ "../views/cardgame/KompassBio.vue"),
				meta:      {
					title: "Hausgemacht - das Biografiespiel",
				},
			},
			{
				path:      "einverstanden",
				alias:     "sprachspiel",
				name:      'sprachspiel',
				component: () => import(/* webpackChunkName: "spiel" */ "../views/cardgame/KompassSprache.vue"),
				meta:      {
					title: "Einverstanden - das Sprachspiel",
				},
			},
			{
				path:      "mitgedacht",
				alias:     "aufgabenspiel",
				name:      'aufgabenspiel',
				component: () => import(/* webpackChunkName: "spiel" */ "../views/cardgame/KompassAufgaben.vue"),
				meta:      {
					title: "Mitgedacht - das Aufgabenspiel",
				},
			},

		]
	},
	{
		path:      "/entfaltung-als-app",
		alias:     "/app",
		component: () => import(/* webpackChunkName: "entf" */ "../views/App.vue"),
		meta:      {
			title: "Entfalter - Entfaltung als App",
		},
	},
	{
		name:      "Konzept",
		path:      "/entfaltung-als-konzept",
		alias:     "/konzept",
		component: () =>
			           import(/* webpackChunkName: "concept" */ "../views/Concept.vue"),
		meta:      {
			title: "Entfalter - Entfaltung als Konzept",
		},
	},
	{
		name:      "Demo",
		path:      "/demo",
		redirect: '/',
		// component: () => import(/* webpackChunkName: "demo" */ "../views/Demo.vue"),
		// meta:      {
		// 	title: "Entfalter - Schnupper-Version",
		// },
	},
	{
		path:      "/match",
		component: () =>
			           import(/* webpackChunkName: "match" */ "../views/Match.vue"),
		meta:      {
			title: "Entfalter - Schnuppermatching",
		},
	},
	{
		path:      "/offers",
		alias:     "/preise",
		name:      "Offers",
		component: () =>
			           import(/* webpackChunkName: "offers" */ "../views/Offers.vue"),
		props:     true,
		meta:      {
			title: "Angebote",
		},
	},
	{
		path:      "/info",
		name:      "Info",
		component: () => import(/* webpackChunkName: "info" */ "../views/Info.vue"),
		props:     true,
		meta:      {
			title: "Kontakt aufnehmen",
		},
	},
	{
		path:      "/impressum",
		component: () =>
			           import(/* webpackChunkName: "legal" */ "../views/Impressum.vue"),
		meta:      {
			title: "Entfalter - Impressum",
		},
	},
	{
		path:      "/datenschutz",
		component: () =>
			           import(
				           /* webpackChunkName: "dataprotection" */ "../views/Datenschutz.vue"
				           ),
		meta:      {
			title: "Entfalter - Datenschutz",
		},
	},

	//
	// App Links (forwarding)
	{
		path:     "/registerconfirm/:userId?/:code?",
		redirect: (to) => {
			window.location = `https://app.entfalter.de/registerconfirm/${
				to.params.userId || ""
			}/${ to.params.code || "" }`;
		},
	},
	{
		path:     "/registrieren/:code?",
		redirect: (to) => {
			window.location = `https://app.entfalter.de/registrieren/${
				to.params.code || ""
			}`;
		},
	},

	// Catch 404s
	{
		path:      "*",
		component: () =>
			           import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
	},
];

const router = new VueRouter({
	mode: process.env.CORDOVA_PLATFORM ? "hash" : "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return {x: 0, y: 0}
	},
});

router.beforeEach((toRoute, fromRoute, next) => {
	window.scrollTo(0, 0)
	window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "Entfalter";

	next();
});

export default router;
