<template>
	<div id="app">
		<NavigationTop/>
		<transition
			name="fade"
			tag="div"
			mode="out-in"
			@beforeLeave="beforeLeave"
			@enter="enter"
			@afterEnter="afterEnter"
		>
			<router-view/>
		</transition>
		<NotificationComponent/>
		<Footer/>
		<vue-snotify></vue-snotify>
	</div>
</template>


<script>
import NavigationTop         from "./components/NavigationTop";
import Footer                from "./components/Footer";
import NotificationComponent from "./components/Notification";

export default {
	components: {NotificationComponent, Footer, NavigationTop},

	data() {
		return {
			cloud:    null,
			cloudPos: 0,
		};
	},
	mounted() {
		document.addEventListener("scroll", this.handleScroll);
		this.handleScroll();
	},

	methods: {
		handleScroll() {
			this.cloudPos = window.scrollY * 0.6;
			this.cloud = document.getElementsByClassName("feature__cloud")[0];
			if (this.cloud)
				this.cloud.style.transform = `translateY(${ this.cloudPos }px)`;
		},
		beforeLeave(element) {
			this.prevHeight = getComputedStyle(element).height;
		},
		enter(element) {
			const {height} = getComputedStyle(element);

			element.style.height = this.prevHeight;

			setTimeout(() => {
				element.style.height = height;
			});
		},
		afterEnter(element) {
			element.style.height = "auto";
		},
	},
	beforeDestroy() {
		document.removeEventListener("scroll", this.handleScroll);
	},
};
</script>
