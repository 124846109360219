import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { Utils } from "./utils/utils";
import AudioPlayer from "./components/AudioPlayer";
import vueVimeoPlayer from "vue-vimeo-player";
import VueAnalytics from "vue-analytics";
import VueScrollTo from "vue-scrollto";
// import VueSelect                    from 'vue-select'
// import VueStepper from 'vue-number-stepper'
import Snotify, { SnotifyPosition } from "vue-snotify";

Vue.config.productionTip = false;

Vue.component("audioPlayer", AudioPlayer);
// Vue.component('v-select', VueSelect.VueSelect)
Vue.use(vueVimeoPlayer);

const options = {
  toast: {
    position: SnotifyPosition.rightBottom,
  },
  global: {
    showProgressBar: false,
    pauseOnHover: true,
    closeOnClick: true,
    newOnTop: true,
    maxOnScreen: 1,
    maxAtPosition: 1,
    oneAtTime: true,
    preventDuplicates: true,
    timeout: 5000,
  },
};
Vue.use(Snotify, options);

Vue.use(VueScrollTo);

// Our utilities
Vue.prototype.$utils = new Utils();

// Check WebP Support
if (Vue.prototype.$utils.supportsWebP())
  document.body.classList.add("supports-webp");

// ----------
// Analytics : Track all pageviews
//
Vue.use(VueAnalytics, {
  id: "UA-147534625-1",
  router,
  autoTracking: {
    pageviewTemplate(route) {
      return {
        page: route.path,
        title: document.title,
        location: window.location.href,
      };
    },
  },
  debug: {
    // Output a lot of debug stuff in console:
    sendHitTask: process.env.NODE_ENV === "production",
  },
  beforeFirstHit() {
    // this is right after the tracker and before every other hit to Google Analytics
    // console.log(window['ga-disable-UA-147534625-1'])
    if (!localStorage.getItem("www_entfalter_tracking_optout")) {
      console.log("NOT OPTED YET!");
    }
  },
  beforeCreate() {
    Vue.$snotify = this.$snotify;
  },
  // It is initially enabled! We use localStorage (for now) to store a user's choice.
  disabled: localStorage.getItem("www_entfalter_tracking_optout") === "1",
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
