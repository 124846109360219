import axios from 'axios';
//const axios_1 = require("axios");
//const Observable_1 = require("rxjs/Observable");

/**
 * Utility class
 *
 * @constructor
 */
export class Utils {
	constructor() {
		this._httpClient = axios.default.create({})
	}

	supportsWebP() {
		return (document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0);
	}

	/**
	 * The colour collection for dynamic colour changes based on branchId
	 * @param branchId  // TODO: use branchIndex and DEPRECATE!
	 * @returns {string}
	 * @deprecated
	 */
	getColors(branchId, secondShade) {
		// console.info('This method will be deprecated')
		let colors = (secondShade) ?
			['#0099cc', '#f29668', '#9ed4ed', '#98c76e', '#98c76e', '#aaaaaa'] :
			['#0099cc', '#ff6633', '#3399ff', '#00a39e', '#74ba57', '#aaaaaa']

		return colors[branchId]
	}

	/**
	 * Return a path to an mp3 file based on current location [page and sub-section]
	 * @param page STRING enumerated list : home | intro | test | ...
	 * @param index :: NOTE: this is NOT the branchID but the index .
	 */
	getSounds(page, index) {
		let sounds = []
		let prefix = `https://assets.entfalter.de/mp3/`

		switch (page) {
			case 'home': {
				sounds = [
					'01_Startseite/00_Prelude_Weil_sich_heute.mp3',
					'01_Startseite/01_Willkommen_beim_Entfalter.mp3',
					'01_Startseite/02_Auf_dem_Wegvzum_eigenen_Personlichkeitsprofil.mp3',
					'01_Startseite/03_Vom_persoenlichen_Profil.mp3',
					'01_Startseite/04_Dauer.mp3',
					'01_Startseite/05_Adressaten.mp3',
					'01_Startseite/06_Theoriebasis.mp3',
				]
				break
			}

			default:
				return null
		}
		// Return single string
		// console.log('SOUND ::: ', page, index)
		// console.log('..', prefix + sounds[+[index]])
		return prefix + sounds[+[index]]
	}

	// @deprecated
	payment(payment) {
		console.warn('utisl:payment is deprecated')
		const {payId, payType, payAmount} = payment
		let payMail = "katharinalegde@gmail.com"
		console.log(payment)
		return this._httpClient.post(`https://cms.entfalter.de/service/eventHandlerDev.php?func=entfalter&task=payment&payId=${ payId }&payMail=${ payMail }&payType=${ payType }&payAmount=${ payAmount }`)
	}

	validateLocalStorageData(key) {
		let res = null
		try {
			res = JSON.parse(localStorage.getItem(key))
		} catch (e) {
			console.warn('Corrupt localStorage data')
			localStorage.setItem(key, null)
		}
		return res
	}

	/**
	 * Return array with template parts for pricing options
	 *
	 * @return {Array}
	 */
	getPricingOptions() {
		return [
			// The array to check what to show is like this:
			// in: [0 = first container (just text list), 1 = light [gone!], 2 = basic, 3 = plus, 4 = group]

			{
				t:    'Entfaltungsprofil',
				in:   [0, 2, 3, 4],
				icon: ['', '', '', '', ''],
			},
			{
				t:    'Entfaltungskonzept',
				in:   [0, 2, 3, 4],
				icon: ['', '', '', '', ''],
			},
			{
				t:    'Persönliches Matchingprofil',
				in:   [0, 2, 3, 4],
				icon: ['', '', '', '', ''],
			},
			{
				t:    'Matching mit bestehenden Referenzprofilen',
				in:   [0, 2, 3, 4],
				icon: ['', '', '', '', ''],
			},
			{
				t:    'Anlegen eigener Referenzprofile',
				in:   [0, 3, 4],
				icon: ['', '', '', '8 Profile', 'beliebig viele'],
			},
			{
				t:    'Bilden individueller Gruppen',
				in:   [0, 3, 4],
				icon: ['', '', '', 'eine Gruppe', 'beliebig viele'],
			},
			{
				t:    'Teilen eigener Referenzprofile in Gruppen',
				in:   [0, 3, 4],
				icon: ['', '', '', '', ''],
			},
			{
				t:    'Matching mit eigenen Referenzprofilen',
				in:   [0, 3, 4],
				icon: ['', '', '', '', ''],
			},
			{
				t:    'Matching mit anderen Referenzprofilen',
				in:   [0, 4],
				icon: ['', '', '', '', ''],
			}
		]
	}


	getSwiperOptions(opts = {}) {
		return {...{
			loop:          true,
			slidesPerView: 6,
			spaceBetween:  10,
			breakpoints:   {
				1600: {
					slidesPerView: 5,
					spaceBetween:  5
				},
				768: {
					slidesPerView: 4,
					spaceBetween:  5
				},
				640: {
					slidesPerView: 1,
					spaceBetween:  10
				},
			},
			pagination:    {
				el: '.swiper-pagination'
			},
			navigation:    {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		}, ...opts}
	}

}
