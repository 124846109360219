<template>
	<div class="home m-0">
		<section class="section first full home">
			<div class="container-- vid">
				<vimeo-player v-if="!environment.CORDOVA_PLATFORM" ref="main_player" :class="{'videoframe__full':true, 'ready': mainVideo.playerReady}"
				              video-id="432447864"
				              :options="mainVideo.options" :controls="false" :loop="true"
				              @ready="onMainVideoReady"/>

				<div v-if="environment.CORDOVA_PLATFORM">
					<video ref="main_player" class="videoframe__full ready" loop autoplay muted playsinline>
						<source src="video/intro_360.mp4" type="video/mp4">
					</video>
				</div>

			</div>

			<transition name="fade-in">
				<button ref="main_unmute" class="btn__unmute blurred__bd" v-if="mainVideo.options.muted" @click="toggleMainVideoMute">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.77 22.33" class="icon_audio">
						<g id="is__muted">
							<path stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-miterlimit="10" d="M17.54 8.35l6.57 6.57M17.56 14.95l6.57-6.58"/>
						</g>
						<path fill="#000000"
						      d="M0 7.48c.03-.02.08-.03.08-.05.19-1.02.88-1.54 1.79-1.85.11-.04.25-.03.37-.03h3.09c.36 0 .36.01.95.33.62-.46 1.25-.93 1.88-1.4.82-.61 1.63-1.21 2.44-1.83.95-.72 1.9-1.45 2.85-2.18.17-.13.34-.26.52-.36.44-.24.92-.1 1.18.32.15.25.21.51.21.8-.01 1.74 0 3.49 0 5.23v14.56c0 .21 0 .41-.08.62-.25.65-.86.87-1.43.49-.26-.18-.51-.37-.76-.56-1.72-1.3-3.45-2.6-5.17-3.9-.38-.29-.75-.59-1.12-.88-.12-.09-.26-.17-.41-.27-.34.43-.8.49-1.29.48-.94-.02-1.89-.04-2.83 0-.95.04-2.03-.8-2.18-1.84 0-.03-.06-.06-.09-.1V7.48zm14.39 13.9V.97c-2.58 1.95-5.13 3.87-7.66 5.78v3.09c0 .15-.04.31-.08.46-.05.2-.24.27-.4.27-.17 0-.35-.07-.4-.27a1.41 1.41 0 01-.07-.41c-.01-.94 0-1.89 0-2.83 0-.39-.15-.54-.54-.54-.98-.01-1.96.01-2.93 0C1.58 6.5.94 7.27.95 7.88c.02 2.26.01 4.52.01 6.78 0 .12 0 .25.03.37.2.65.81 1.09 1.51 1.1.87.01 1.74 0 2.61 0 .38 0 .56-.15.64-.55.04-.21.15-.37.36-.4.21-.03.42-.04.52.23.05.12.17.23.28.31.48.38.97.76 1.46 1.13.79.59 1.58 1.17 2.37 1.76 1.21.91 2.41 1.82 3.65 2.77zM6.72 12.6c0-.12.01-.25 0-.37-.02-.23-.26-.47-.47-.47-.24-.01-.47.22-.49.51-.01.25-.02.49 0 .74.02.24.28.46.49.45.18-.01.43-.23.46-.43.03-.14.01-.28.01-.43z"/>
					</svg>
					<!--          <small>TON EIN</small>-->
				</button>
			</transition>
			<transition name="fade-in">
				<button ref="main_mute" class="btn__unmute blurred__bd br" v-if="!mainVideo.options.muted" @click="toggleMainVideoMute">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.77 22.33" class="icon_audio">
						<path fill="#000000"
						      d="M0 7.48c.03-.02.08-.03.08-.05.19-1.02.88-1.54 1.79-1.85.11-.04.25-.03.37-.03h3.09c.36 0 .36.01.95.33.62-.46 1.25-.93 1.88-1.4.82-.61 1.63-1.21 2.44-1.83.95-.72 1.9-1.45 2.85-2.18.17-.13.34-.26.52-.36.44-.24.92-.1 1.18.32.15.25.21.51.21.8-.01 1.74 0 3.49 0 5.23v14.56c0 .21 0 .41-.08.62-.25.65-.86.87-1.43.49-.26-.18-.51-.37-.76-.56-1.72-1.3-3.45-2.6-5.17-3.9-.38-.29-.75-.59-1.12-.88-.12-.09-.26-.17-.41-.27-.34.43-.8.49-1.29.48-.94-.02-1.89-.04-2.83 0-.95.04-2.03-.8-2.18-1.84 0-.03-.06-.06-.09-.1V7.48zm14.39 13.9V.97c-2.58 1.95-5.13 3.87-7.66 5.78v3.09c0 .15-.04.31-.08.46-.05.2-.24.27-.4.27-.17 0-.35-.07-.4-.27a1.41 1.41 0 01-.07-.41c-.01-.94 0-1.89 0-2.83 0-.39-.15-.54-.54-.54-.98-.01-1.96.01-2.93 0C1.58 6.5.94 7.27.95 7.88c.02 2.26.01 4.52.01 6.78 0 .12 0 .25.03.37.2.65.81 1.09 1.51 1.1.87.01 1.74 0 2.61 0 .38 0 .56-.15.64-.55.04-.21.15-.37.36-.4.21-.03.42-.04.52.23.05.12.17.23.28.31.48.38.97.76 1.46 1.13.79.59 1.58 1.17 2.37 1.76 1.21.91 2.41 1.82 3.65 2.77zM6.72 12.6c0-.12.01-.25 0-.37-.02-.23-.26-.47-.47-.47-.24-.01-.47.22-.49.51-.01.25-.02.49 0 .74.02.24.28.46.49.45.18-.01.43-.23.46-.43.03-.14.01-.28.01-.43z"/>
					</svg>
					<!--          <small>TON AUS</small>-->
				</button>
			</transition>

		</section>

		<!-- CTA -->
		<!--    <img src="img/feature-clouds-down.svg" style="position: absolute;width: 100%;left:0;"/>-->
		<!-- <section class="section cta">
		  <h2>Den Entfalter kostenlos testen.</h2>
		  <p>Mit dem Entfalter <sup>Light</sup> bekommst du einen ersten Eindruck vom Tool.</p>
		  <entfalter-button v-scroll-to="'#cta'" v-show="demoAvailable">Kostenlos testen</entfalter-button>
		  <entfalter-button v-scroll-to="'#cta'" v-show="!demoAvailable">Jetzt starten</entfalter-button>
		  <br/><a href="https://app.entfalter.de">oder Einloggen</a>
		</section> -->

		<section class="section has-o-right-3 pb-0">


			<section class="section is-vcentered pt-0">
				<div class="container content">
					<div class="columns is-mobile ">
						<div class="column is-full-mobile is-half-tablet">
							<picture>
								<source type="image/webp"
								        srcset="img/e_app_start-3x.png.webp 3019w,
                                    img/e_app_start-2x.png.webp 1000w,
                                    img/e_app_start.png.webp 300w"
								/>
								<source srcset="img/e_app_start-3x.png 3019w,
                            img/e_app_start-2x.png 1000w"
								/>
								<img src="img/e_app_start.png" class="e__app small__center fade-in" alt="Entfalter Start"/>
							</picture>
						</div>
						<div class="column ">
							<h2>Was das Tool macht und kann</h2>
							<p>Mit dem Entfalter lernst Du Dich besser kennen: Denn er hilft, den Kopf aufzuräumen.
							   Das schafft innere Ordnung und zeigt das Gefüge Deiner Interessen. So kommst Du
							   Deinen Wünschen, Vorstellungen und Fähigkeiten auf die Spur. Das gelingt durch eine
							   strukturiert angelegte Selbstbefragung.</p>
						</div>

					</div>
				</div>
			</section>
		</section>

		<!-- Octa BG Container -->
		<!--        :style="'background-size:'+orightScale"-->
		<div class="main__container  " style="margin-top:-1px;">
			<section class="section is-vcentered "> <!-- has-o-left-2-->
				<div class="container content ">
					<div class="columns is-mobile">
						<div class="column is-full-mobile is-half-tablet">
							<div class="columns is-mobile">
								<div class="column pr-0 mr-0 ">
									<picture>
										<source type="image/webp"
										        srcset="img/e_app_aufgaben-3x.png.webp 3019w,
                                    img/e_app_aufgaben-2x.png.webp 1000w,
                                    img/e_app_aufgaben.png.webp 300w"
										/>
										<source srcset="img/e_app_aufgaben-3x.png 3019w,
                            img/e_app_aufgaben-2x.png 1000w"
										/>
										<img src="img/e_app_aufgaben.png" class="e__app small overflow__top fade-in" alt="Entfalter Start"/>
									</picture>
								</div>
								<div class="column pl-0 ml-0">
									<picture>
										<source type="image/webp"
										        srcset="img/e_app_rollen-3x.png.webp 3019w,
                                    img/e_app_rollen-2x.png.webp 1000w,
                                    img/e_app_rollen.png.webp 300w"/>
										<source srcset="img/e_app_rollen-3x.png 3019w,img/e_app_rollen-2x.png 1000w"/>
										<img src="img/e_app_rollen.png" class="e__app small fade-in" alt="Entfalter Start"/>
									</picture>
									<!--                                    <img src="img/e_app_abpl.png" class="e__app small fade-in" alt="Entfalter Start"-->
									<!--                                         srcset="img/e_app_abpl-2x.png 3019w, img/e_app_abpl-2x.png 2013w,"/>-->
								</div>
							</div>
						</div>

						<div class="column">
							<h2>Deine Aufgaben?</h2>
							<p>

								Es gibt eine große Fülle von Aufgaben. Wir haben sie für Dich geordnet. So kannst Du
								herausfinden, welche Aufgaben Dir wichtig sind und auch Deine Kompetenz
								dazu einschätzen.

							</p>
							<h2>Deine Rollen?</h2>
							<p>
								Rollen gibt es überall, wo gearbeitet wird. Wir haben 23 typische Rollen beschrieben,
								die Du für Dich als mehr oder weniger interessant einschätzen kannst.
								Kennt man seine Wunschrollen und hat man sich seine Kompetenzen dazu bewusst
								gemacht, hilft das auch dabei, seine Berufswünsche genauer zu formulieren.
							</p>
						</div>
					</div>

				</div>
			</section>

			<section class="section is-vcentered ">
				<div class="container content ">
					<div class="columns is-mobile">
						<div class="column">
							<div class="columns is-mobile">
								<div class="column pr-0 mr-0">
									<picture>
										<source type="image/webp"
										        srcset="img/e_app_welten-3x.png.webp 3019w,
                                    img/e_app_welten-2x.png.webp 1000w,
                                    img/e_app_welten.png.webp 300w"
										/>
										<source srcset="img/e_app_welten-3x.png 3019w,
                            img/e_app_welten-2x.png 1000w"
										/>
										<img src="img/e_app_welten.png" class="e__app small overflow__top fade-in" alt="Entfalter Start"/>
									</picture>
								</div>
								<div class="column pl-0 ml-0">
									<picture>
										<source type="image/webp"
										        srcset="img/e_app_abpl-3x.png.webp 3019w,
                                    img/e_app_abpl-2x.png.webp 1000w,
                                    img/e_app_abpl.png.webp 300w"
										/>
										<source srcset="img/e_app_abpl-3x.png 3019w,
                            img/e_app_abpl-2x.png 1000w"
										/>
										<img src="img/e_app_abpl.png" class="e__app small fade-in" alt="Entfalter Start"/>
									</picture>
									<!--                                    <img src="img/e_app_abpl.png" class="e__app small fade-in" alt="Entfalter Start"-->
									<!--                                         srcset="img/e_app_abpl-2x.png 3019w, img/e_app_abpl-2x.png 2013w,"/>-->
								</div>
							</div>
						</div>
						<div class="column is-full-mobile">
							<h2>Deine Welt/en?</h2>
							<p>
								Aufgaben und Rollen sind in vielen verschiedenen Welten zuhause. Die bestehende
								Fülle haben wir unter fünf Oberbegriffen gebündelt und stellen sie Dir auch in
								Bildern vor. Du merkst, inwieweit Du Dich jeweils einlassen könntest und möchtest.


							</p>
							<h2>Dein Arbeitsplatz?</h2>
							<p>
								Der Platz, an dem man arbeitet, hat Folgen für die eigene Lebensgestaltung.
								Deshalb ist es wichtig, die Hoffnungen und Erwartungen, die man mit einem
								konkreten Arbeitsplatz verbinden möchte, zu kennen.
							</p>
							<!-- <entfalter-button :colorscheme="'light'" @click="openLink('entfaltung-als-app')">mehr erfahren</entfalter-button> -->
						</div>
					</div>
				</div>
			</section>


			<section class="section is-vcentered ">
				<div class="container content">
					<div class="columns is-mobile">
						<!-- <div class="column is-full-mobile">
						  <picture>
							<source type="image/webp"
									srcset="img/e_app_devices.png.webp 300w"
							/>
							<source srcset="img/e_app_devices.png 300vw"/>
							<img src="img/e_app_devices.png" class="e__app wide overflow__top fade-in" alt="Entfalter Start"/>
						  </picture>
						</div> -->
						<div class="column is-full-mobile is-half-tablet">
							<div class="columns is-mobile">
								<div class="column  pr-0 mr-0">
									<picture>
										<source type="image/webp"
										        srcset="img/e_app_ausw-3x.png.webp 3019w,
                                    img/e_app_ausw-2x.png.webp 1000w,
                                    img/e_app_ausw.png.webp 300w"
										/>
										<source srcset="img/e_app_ausw-3x.png 3019w,
                            img/e_app_welten-2x.png 1000w"
										/>
										<img src="img/e_app_ausw.png" class="e__app small overflow__top fade-in" alt="Entfalter Start"/>
									</picture>
								</div>
								<div class="column  pl-0 ml-0">
									<picture>
										<source type="image/webp"
										        srcset="img/e_app_auswkonz-3x.png.webp 3019w,
                                    img/e_app_auswkonz-2x.png.webp 1000w,
                                    img/e_app_auswkonz.png.webp 300w"
										/>
										<source srcset="img/e_app_auswkonz-3x.png 3019w,
                            img/e_app_abpl-2x.png 1000w"
										/>
										<img src="img/e_app_auswkonz.png" class="e__app small fade-in" alt="Entfalter Start"/>
									</picture>
									<!--                                    <img src="img/e_app_abpl.png" class="e__app small fade-in" alt="Entfalter Start"-->
									<!--                                         srcset="img/e_app_abpl-2x.png 3019w, img/e_app_abpl-2x.png 2013w,"/>-->
								</div>
							</div>
						</div>

						<div class="column">
							<h2>Dein Entfaltungsprofil und <br/> Dein Entfaltungskonzept</h2>
							<p>Das Entfaltungsprofil entsteht als Folge Deiner Selbstbefragung. Du siehst Deine Einschätzungen alle in einem Bild. Das zeigt Dir, wer Du jetzt bist. Ein neuer Durchgang durch die Befragungsbereiche ermöglicht Dir, Deine Entfaltungsüberlegungen festzuhalten. Im Ergebnis siehst Du, wie Du Dich entfalten möchtest. Daraus ergibt sich Dein Entfaltungskonzept. Dessen Darstellung, verbunden mit Deinem Entfaltungsprofil, kannst Du online studieren und auch zum Ausdrucken anfordern.
							</p>
							<!-- <entfalter-button @click="openLink('entfaltung-als-konzept')">mehr erfahren</entfalter-button> -->
						</div>
					</div>
				</div>
			</section>

			<section class="section is-vcentered ">
				<div class="container content">
					<div class="columns is-mobile">
						<div class="column is-full-mobile">
							<picture>
								<source type="image/webp"
								        srcset="img/e_app_menuMac-3x.png.webp 3019w,
                                    img/e_app_menuMac-2x.png.webp 1000w,
                                    img/e_app_menuMac.png.webp 300w"
								/>
								<source srcset="img/e_app_menuMac-3x.png 3019w,
                            img/e_app_menuMac-2x.png 1000w"
								/>
								<img src="img/e_app_menuMac.png" class="e__app wide overflow__top fade-in" alt="Entfalter Start"/>
							</picture>

						</div>

						<div class="column">
							<h2>So gestaltet sich deine Arbeit mit dem Entfalter</h2>
							<p> Man entscheidet selbst, in welcher Reihenfolge und wie lange man mit dem Entfalter
							    arbeitet. Damit das geht, ist der Entfalter so eingerichtet, dass er über den individuell
							    benötigten Zeitraum (zwischen einer Stunde bis zu mehreren Tagen) hin bearbeitet
							    werden kann. Auch kann man zwischengespeicherte Eintragungen immer wieder
							    ändern. Erst wenn man die Auswertung anfordert, kann man nichts mehr ändern. <br/>
								<br/>
							    Übrigens: Alle Texte werden Dir auch vorgelesen, wenn Du auf den Tonbalken klickst.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section class="section is-vcentered">
				<div class="container content">
					<div class="columns is-mobile">
						<div class="column">
							<picture>
								<source type="image/webp"
								        srcset="img/e_app_matchingMac-3x.png.webp 3019w,
                                    img/e_app_matchingMac-2x.png.webp 1000w,
                                    img/e_app_matchingMac.png.webp 300w"
								/>
								<source srcset="img/e_app_matchingMac-3x.png 3019w,
                            img/e_app_matchingMac-2x.png 1000w"
								/>
								<img src="img/e_app_matchingMac.png" class="e__app wide overflow__top fade-in" alt="Entfalter Start"/>
							</picture>
						</div>
						<div class="column is-full-mobile">
							<h2>Dein Matching mit über 200 Referenzprofilen</h2>
							<p> Der Entfalter erstellt aus Deinen Angaben Dein Matchingprofil. Das matcht er für Dich mit Referenzprofilen von Berufen, Studienangeboten, Ehrenämtern und Praktika. In diesem Matching siehst Du – nachvollziehbar dargestellt – den Grad der Übereinstimmung (in Prozent) zwischen Dir und den Referenzprofilen. Dabei können sich durchaus unerwartete Möglichkeiten für anstehende Entscheidungen zeigen.
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>

		<section class="section is-vcentered has-vid-bg">
			<div class="container content">
				<h2>Stimmen zum Entfalter</h2>
				<div class="columns">
					<div class="column is-full is-relative">
						<!--            <iframe id="mainvid" v-if="!environment.CORDOVA_PLATFORM" src="https://player.vimeo.com/video/432077494" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>-->
						<vimeo-player v-if="!environment.CORDOVA_PLATFORM" ref="vid2"
						              video-id="432077494"
						              :controls="true" :loop="false"
						              @ready="onVideo2Ready"
						              @ended="onVideo2Ended"
						              @play="video2Playing = true"
						              @pause="video2Playing = false"
						/>
						<div v-if="environment.CORDOVA_PLATFORM">
							<video class="ready" controls>
								<source src="video/entfalter_360.mp4" type="video/mp4">
							</video>
						</div>
						<div id="vid2--ovl" v-show="!video2Playing">
							<div class="--v1" @click="videoControl(41)"></div>
							<div class="--v2" @click="videoControl(118)"></div>
							<div class="--v3" @click="videoControl(240)"></div>
							<div class="--v4" @click="videoControl(70)"></div>
							<div class="--v5" @click="videoControl(0)"></div>
							<div class="--v6" @click="videoControl(151)"></div>

						</div>
					</div>
					<div class="column is-full">
						<entfalter-button @click="openLink('entfaltung-als-konzept')">mehr erfahren</entfalter-button>
					</div>
				</div>
			</div>

		</section>


		<svg class="tri__down" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 102" preserveAspectRatio="none">
			<path d="M0 0 L50 100 L100 0 Z" fill="#a0d2ed"></path>
		</svg>


	</div>
</template>

<script>
// @ is an alias to /src


import EntfalterButton from "../components/entfalter-button.component";


export default {
	name:       'Home',
	components: {EntfalterButton},
	props:      ["id"],
	data() {
		return {
			fadeInElements:   [],
			orightScale:      '50vw',
			mainVideo:        {
				el:          null,
				options:     {
					muted:    true,
					autoplay: true,
				},
				playerReady: false
			},
			video2CorePlayer: null,
			video2Playing:    false,
			environment:      process.env
		}
	},
	mounted() {
		this.mainVideo.el = this.$refs['main_player']
		this.fadeInElements = Array.from(document.getElementsByClassName('fade-in'))
		document.addEventListener('scroll', this.handleScroll)
		this.handleScroll()
		// Vimeo Player API for controlling the iframe
		// const iframe = document.getElementById('mainvid');
		// this.player = new Player(iframe); //
		// console.log(player)
	},
	computed: {
		demoAvailable() {
			return this.$utils.validateLocalStorageData('storedData') ? false : true
		}
	},
	methods:  {
		handleScroll() {
			// Handle main video: Mute if scrolled out of view
			if (window.scrollY > window.innerHeight && !this.mainVideo.options.muted)
				this.toggleMainVideoMute()


			// Handle Phone images
			for (var i = 0; i < this.fadeInElements.length; i++) {
				let elem = this.fadeInElements[i]
				if (this.isElemVisible(elem)) {
					elem.style.opacity = '1'
					elem.style.transform = 'scale(1)'
					elem.style.transform = 'translateY(0)'
					this.fadeInElements.splice(i, 1) // only allow it to run once
				}
			}
			// Scale background?
			// this.orightScale = '100vw'
		},
		isElemVisible(el) {
			let rect = el.getBoundingClientRect()
			let elemTop = rect.top + 200 // 200 = buffer
			let elemBottom = rect.bottom
			return elemTop < window.innerHeight && elemBottom >= 0
		},

		/**
		 * Main Video : onReady
		 * Once ready, check muted state and show | hide the unmute button
		 */
		onMainVideoReady() {
			this.mainVideo.playerReady = true
			// @deprecatedL: show icon once video is ready
			// document.getElementsByClassName('wwwlogo tr')[0].style.display = 'block'
			// document.getElementsByClassName('wwwlogo tr')[0].style.opacity = '1'
		},

		toggleMainVideoMute() {
			this.mainVideo.options.muted = !this.mainVideo.options.muted
			if (this.mainVideo.options.muted) {
				if (this.environment.CORDOVA_PLATFORM)
					this.$refs.main_player.muted = true
				else
					this.$refs.main_player.mute()
			} else {
				if (this.environment.CORDOVA_PLATFORM)
					this.$refs.main_player.muted = false
				else
					this.$refs.main_player.unmute()
			}
		},

		openLink(to) {
			this.$router.push(to)
		},

		// -----
		// Video 2: get corePlayer
		onVideo2Ready(instance) {
			this.video2CorePlayer = instance
		},

		async videoControl(to) {
			if (!this.video2CorePlayer) return
			// Await state. Avoid play interruptions
			const paused = await this.video2CorePlayer.getPaused()
			if (!paused)
				this.video2CorePlayer.pause()
			this.video2CorePlayer.setCurrentTime(to)
			this.video2CorePlayer.play()
		},

		onVideo2Ended() {
			// Rewind won't bring up the poster again. We have to reInit
			this.$refs.vid2.update()
			// this.video2CorePlayer.setCurrentTime(0) // rewind
		}


	},

	beforeDestroy() {
		document.removeEventListener('scroll', this.handleScroll)
	}

}
</script>

<style lang="scss">

// Gotta emulate the width of the video inside the iframe. This is not simple. So we use nearest...
#vid2--ovl {
	cursor   : pointer;
	position : absolute;
	top      : 0;
	left     : 0;
	width    : 100%;
	height   : 90%;
	overflow : hidden;

	div {
		position : absolute;
		width    : 30%;
		height   : 37%;
		}

	.--v1 {
		left : 35%;
		top  : 0;
		}

	.--v2 {
		left : 65%;
		top  : 0;
		}

	.--v3 {
		left   : 35%;
		top    : 37%;
		height : 35%;
		}

	.--v4 {
		left   : 65%;
		top    : 37%;
		height : 35%;
		}

	.--v5 {
		left   : 35%;
		top    : 72%;
		height : 30%;
		}

	.--v6 {
		left   : 65%;
		top    : 72%;
		height : 30%;
		}
	}

</style>
